import React, { Component } from 'react';
import PropTypes from "prop-types";
import { RecipeGrid } from "../styles";
import { CategoryRecipeContainer, RecipesContainer } from "./styles";
import { NoComments } from "../../SocialFeeds/styles";
import { FeaturedRecipesContainer, FeaturedContainer } from "../RecipeDetails/styles";
import AdventurePointDashBoard from '../../AdventurePointDashBoard';
import { connect } from "react-redux";
import { getRecipeDetailsByCategory } from "../../../redux/actions";
import { Waiting } from "../../index";
import { HaederName, ImageIcon } from '../../Journeys/styles';
import RecommendedRecipe from '../recommendedRecipe';
import StyledDropDown from '../../DropDown/dropDownRecipes';
import isNull from "lodash/isNull";
import { ImageUrlNew } from '../../../utils/constants';
import { AdminButtons } from '../../Article/styles';
import { withTranslation } from 'react-i18next';

class RecipeCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: '',
      dropdown: 0,
      open: false,
      selectedItem: '',
    }
  }
  componentDidMount() {
    const { location, getRecipeDetailsByCategory } = this.props;
    const StringArray = location.pathname.split('/');
    let categoryName = StringArray[(StringArray.length - 1)];
    getRecipeDetailsByCategory(categoryName);
    this.setState({ categoryName: categoryName });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { location, getRecipeDetailsByCategory } = this.props;
      const StringArray = location.pathname.split('/');
      let categoryName = StringArray[(StringArray.length - 1)];
      getRecipeDetailsByCategory(categoryName);
      this.setState({ categoryName: categoryName });
    }
  }
  getCategoryRecipes = (viewRecipesData) => (
    viewRecipesData.map((nutrition, index) => (
      <RecommendedRecipe onClickFavoriteRibbon={this.props.onClickFavoriteRibbon} nutrition={nutrition} key={index} history={this.props.history} margin={index === 1 ? "0px" : "25px"} width={'calc((100% / 2) - 12.5px)'} />
    ))
  );

  showDropDown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
      open: true
    })
  };

  onSelect = (value) => {
    this.setState({
      selectedItem: value
    });
  };

  addCategory = (categoryName, categories) => {
    let index = categories.findIndex((data) => data.recipe_tag.toLowerCase() == categoryName.toLowerCase());
    return index != -1 ? categories[index].image : "test.png";
  };

  render() {
    const { history, recipeCategoryDetails, recipeLoading, recipeCategories, createRecipe, createCategory } = this.props;
    const { categoryName, dropdown } = this.state;
    let categories = [];
    recipeCategories && recipeCategories.map((data) => {
      if (!isNull(data['image'])) {
        categories.push(data);
      }
    });

    if (!recipeCategoryDetails || recipeLoading) {
      return <Waiting />;
    }

    return (
      <div style={{ "marginTop": "-85px" }}>
        <HaederName>
          <div>
            <div>
              <img
                src={`${ImageUrlNew}/${this.addCategory(categoryName, recipeCategories)}`}
              />
            </div>
            <div>
              <span>
                {`${this.props.t(categoryName)}`} {this.props.t("Recipe")}
              </span>
            </div>
          </div>
          <ImageIcon ><img src="/public/images/NewDashboardV2/menuicon.png" alt="back-arrow" onClick={() => this.showDropDown()} />
          </ImageIcon>
        </HaederName>
        {dropdown !== 0 ?
          <StyledDropDown
            title={this.props.t("Adventures Categories")}
            MenuItems={categories}
            isAdventurePage={true}
            history={history}
            onSelect={this.onSelect}
            isOpen={true}
          />
          : ''}
        <RecipesContainer>
          <CategoryRecipeContainer>
            <RecipeGrid padding={'0px'}>
              {recipeCategoryDetails && recipeCategoryDetails.length > 0 ? this.getCategoryRecipes(recipeCategoryDetails) : <NoComments>{this.props.t("No Recipes for this category")}</NoComments>}
            </RecipeGrid>
          </CategoryRecipeContainer>
          <FeaturedContainer width={'32.9'} padding={"25px"}>
            <FeaturedRecipesContainer marginLeft={1} style={{ "marginTop": "-75px" }}>
              {createRecipe &&
                <AdminButtons background={"#F6B479"} onClick={() => history.push('/education/create-recipe')}>
                  <div>
                    <img src="/public/images/NewDashboardV2/createrecipe.png" alt="back-arrow" />
                  </div>
                  <div>
                    {this.props.t("Create Recipe")}
                  </div>
                </AdminButtons>
              }
              {createCategory &&
                <AdminButtons background={"#69C2FF"} onClick={() => history.push('/education/create-recipe-category')} >
                  <div>
                    <img src="/public/images/NewDashboardV2/createjourney.png" alt="back-arrow" />
                  </div>
                  <div>
                    {this.props.t("Create Recipe Category")}
                  </div>
                </AdminButtons>
              }
              <AdventurePointDashBoard />
            </FeaturedRecipesContainer>
          </FeaturedContainer>
        </RecipesContainer>
      </div>
    );
  }
}
RecipeCategory.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  getRecipeDetailsByCategory: PropTypes.func,
  recipeCategoryDetails: PropTypes.array,
  recipeLoading: PropTypes.bool,
  onClickFavoriteRibbon: PropTypes.func,
  recipeCategories: PropTypes.array,
  createRecipe: PropTypes.bool,
  createCategory: PropTypes.bool,
  t: PropTypes.func

};
const mapStateToProps = (state) => ({
  recipeCategoryDetails: state.education.recipeCategoryDetails,
  recipeLoading: state.education.recipeLoading,
  recipeCategories: state.education.recipeCategories,
});
const mapDispatchToProps = (dispatch) => ({
  getRecipeDetailsByCategory: (categoryName) => dispatch(getRecipeDetailsByCategory(categoryName))
});
export default connect(mapStateToProps, mapDispatchToProps)((withTranslation()) (RecipeCategory));